<template>
  <section
    class="cont--hero2"
    :style="{ 'background-image': 'url(' + img01 + ')' }"
  >
    <router-link to="/camp">
      <img :src="titleImg" alt="" class="title__img" />
    </router-link>
    <div class="scroll_down_wrapper">
      <div class="icon-scroll"></div>
      <img
        src="https://static.papayaplay.com/static/assets/images/bs_mobile/new_teaser/global_register/SCROLLDOWN.png"
        class="scroll_down_img"
      />
    </div>
  </section>
</template>

<script>
import topImg01 from "@/assets/academy/ac_top_bg.jpg";
import topText from "@/assets/academy/ac_top_text.png";
//import topText from "@/assets/about/golfmecca_banner.png";

export default {
  name: "Hero",
  data: () => {
    return {
      img01: topImg01,
      titleImg: topText
    };
  }
};
</script>
