<template>
  <nav class="cont--mobile">
    <div class="wrap--mobile">
      <div class="top--logo">
        <router-link to="/"
          ><img src="../../assets/about/logo_white.png" alt=""
        /></router-link>
      </div>
      <div
        class="nav-icon4"
        :class="{ 'icon--open': openFlag }"
        @click="toggleClick()"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="wrap--menus animated fadeInRight" v-if="openFlag">
      <div class="left--menu" @click="openFlag = !openFlag"></div>
      <div class="right--menu">
        <ul>
          <router-link
            :to="item.link"
            v-for="(item, index) in menus"
            :key="index"
            ><li
              :class="{ active: $route.name == item.title.toLowerCase() }"
              @click="openFlag = !openFlag"
            >
              {{ item.title }}
            </li></router-link
          >
          <li>
            <router-link to="/"
              ><img
                @click="openFlag = !openFlag"
                src="../../assets/about/logo-black.png"
                alt=""
            /></router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
//import $ from "jquery";
export default {
  name: "MHeader",
  props: ["menus"],
  data: () => {
    return {
      openFlag: false
    };
  },
  methods: {
    toggleClick: function() {
      let vm = this;
      vm.openFlag = !vm.openFlag;
    }
  },
  created() {}
};
</script>
